import React from "react"
import ExtendedContent from "../components/extendedContent"
import SEO from "../components/seo"
import PrivacyBg from "../images/privacy-policy/privacy-bg.svg"
import PageContainer from "../components/pageContainer"
import styled from "styled-components"
import { graphql } from "gatsby"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"

const PageContainerExtended = styled(PageContainer)`
  background-color: ${({ theme }) => theme.colors.mainWhite};
`
const StyledPageBg = styled.div`
  position: absolute;
  top: 0;
  right:0;
  height: 100%;
  width: 100%;
  background-image: ${"url(" + PrivacyBg + ")"};
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 500px;
  z-index: -1;

  @media(min-width: 992px ) {
    background-size: auto;
  }
`

const StyledPageHeader = styled.div`
  margin-top: 110px;
  color: ${({ theme }) => theme.colors.mainNavyBlue};
  font-family: ${({ theme }) => theme.fonts.headerFont};
  font-size: 40px;
  font-weight: bold;
  width: 200px;
  @media( min-width: 768px ) {
    width: auto;
    font-size: 89px;
    line-height: 86px;
  }
`

class PrivacyPolicyPage extends React.Component {


  componentDidMount() {
    const { dispatch, pageContext } = this.props
    dispatch(setTopColors("bialy"))
    dispatch(toggleSubmenu(false))
    saveUrlsToLocalStorage(pageContext)
  }

  render() {
    const { data } = this.props
    const entries = data.policy.nodes?.[0] || ""
    return (
      <>
        <PageContainerExtended>
          <StyledPageBg/>
          <StyledPageHeader>{entries.title}</StyledPageHeader>
        </PageContainerExtended>
        <ExtendedContent data={entries.archeeTresc}/>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
  isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(PrivacyPolicyPage)


export const query = graphql`
    query($remoteId: ID, $langKey: String) {
            seo: allCraftArcheePolitykaPrywatnosciArcheePolitykaPrywatnosciEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    ...seoPrivacyPolicy
                }
            }
            policy: allCraftArcheePolitykaPrywatnosciArcheePolitykaPrywatnosciEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes {
                    title
                    archeeTopKolory
                    archeeTresc {
                        __typename
                        ...marginesTresc,
                        ... on Craft_archeeTresc_naglowek_BlockType {
                            naglowek
                        }
                        ... on Craft_archeeTresc_opis_BlockType {
                            opis
                        }
                    }
                    seoTitle
                }
            }
    }`

export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}